<script lang="ts">
	import { fade } from 'svelte/transition';
	import Button, { Label } from '@smui/button';
	import Textfield from '@smui/textfield';
	import type { SnackbarComponentDev } from '@smui/snackbar';
	import Snackbar, { Actions } from '@smui/snackbar';
	import { mdiGoogle, mdiTwitter, mdiAccountPlus, mdiClose, mdiLogin, mdiEmailLock } from '@mdi/js';

	import auth from '$stores/authStore';
	import { goto } from '$app/navigation';
	import CenteredPaper from '$lib/CenteredPaper.svelte';
	import SvgIcon from '$lib/SvgIcon.svelte';
	import Paper, { Content, Title } from '@smui/paper';
	import { debugLog } from '$lib/utils';

	// export let placeId: string = '';
	export let email: string = '';
	export let password: string = '';
	let snackbarWithClose: SnackbarComponentDev;

	async function doEmailLogin() {
		const success = await auth.loginWithEmail(email, password);
		if (success) await goto('/app');
	}
	async function doGoogleLogin() {
		resetForm();
		const success = await auth.loginWithGoogle();
		if (success) await goto('/app');
	}
	async function doTwitterLogin() {
		resetForm();
		const success = await auth.loginWithTwitter();
		if (success) await goto('/app');
	}
	function resetForm() {
		debugLog('Resetting form');
		email = '';
		password = '';
		auth.reset();
	}
	function doPasswordReset() {
		auth.sendPasswordReset(email);
		snackbarWithClose.open();
		resetForm();
	}
	function onKeyDown(e: any) {
		if (e.key === 'Enter') {
			doEmailLogin();
		}
	}
</script>

<Snackbar bind:this={snackbarWithClose}>
	<Label>A password reset email has been sent to the email address you specified</Label>
	<Actions>
		<Button title="Dismiss">
			<SvgIcon mdiIcon={mdiClose} />
		</Button>
	</Actions>
</Snackbar>

<CenteredPaper title="Login">
	<Button variant="raised" style="margin: 10px 0; width: 100%" on:click={doGoogleLogin}>
		<SvgIcon mdiIcon={mdiGoogle} />
		<Label>Login with Google</Label>
	</Button>
	<Button variant="raised" style="margin: 10px 0; width: 100%" on:click={doTwitterLogin}>
		<SvgIcon mdiIcon={mdiTwitter} />
		<Label>Login with Twitter</Label>
	</Button>

	<Paper color="primary" variant="outlined" style="width:100%">
		<div class="small-title">Login with Email</div>
		<form name="login" method="dialog">
			<Content>
				<div class="login-form" in:fade>
					<div class="formItem">
						<Textfield
							style="width: 100%;"
							helperLine$style="width: 100%;"
							class="outlined"
							variant="outlined"
							bind:value={email}
							name="email"
							label="Email"
							input$autocomplete="email" />
					</div>
					<div class="formItem">
						<Textfield
							style="width: 100%;"
							helperLine$style="width: 100%;"
							class="outlined"
							variant="outlined"
							type="password"
							on:keydown={onKeyDown}
							bind:value={password}
							name="password"
							label="Password"
							input$autocomplete="current-password" />
					</div>
					{#if $auth.errorOccurred}
						<div class="error-message">Invalid username or password</div>
					{/if}
					<div class="submit-buttons">
						<Button
							on:click={doEmailLogin}
							variant="raised"
							disabled={!email || !password}
							class="login-button">
							<SvgIcon mdiIcon={mdiLogin} />
							Login
						</Button>
						<Button
							on:click={doPasswordReset}
							variant="raised"
							disabled={!email}
							title="Enter your email and click to get a link to change your password">
							<SvgIcon mdiIcon={mdiEmailLock} />
							Reset Password
						</Button>
					</div>
				</div>
			</Content>
		</form>
	</Paper>
	<Button
		variant="raised"
		color="secondary"
		style="margin: 10px 0; width: 100%"
		on:click={() => goto('/signup')}>
		<SvgIcon mdiIcon={mdiAccountPlus} />
		<Label>Sign up for a new account</Label>
	</Button>
</CenteredPaper>

<style>
	.small-title {
		text-align: center;
		font-size: 1.1rem;
		padding-bottom: 10px;
	}
	.login-form {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 10px;
	}
	.error-message {
		text-align: center;
		color: darkred;
	}
	.submit-buttons {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		gap: 16px;
	}
	/* media query for small screens */
	@media (max-width: 450px) {
		.submit-buttons {
			flex-direction: column;
		}
		:global(.mdc-button) {
			font-size: clamp(6px, 6vw, 14px);
			line-height: clamp(7px, 6vw, 14px);
		}
	}
	.formItem {
		display: block;
		margin: 5px;
	}
</style>
